/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Snackbar, Title } from "../../components";
import "./styles.css";
import { noEmptyVaildation } from "../../utils/formValidation";

const Contact = () => {
  const form = useRef();

  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isRender, setIsRender] = React.useState(false);
  const [disable, setDisable] = React.useState(true);

  const reset = () => {
    setName("");
    setMessage("");
    setDisable(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(process.env.REACT_APP_EMAILJS_SERVICE_ID, "sayan_gmail_template", {
        from_name: name,
        message,
        reply_to: "sayanmondal342@gmail.com",
      })
      .then(
        (result) => {
          if (result.status === 200) reset();
        },
        (error) => {
          setErrorMessage(error.text);
          setIsRender(true);
        }
      );

    setTimeout(() => {
      setIsRender(false);
    }, 3000);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (noEmptyVaildation(message) && noEmptyVaildation(e.target.value)) {
      setDisable(false);
    } else setDisable(true);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (noEmptyVaildation(name) && noEmptyVaildation(e.target.value)) {
      setDisable(false);
    } else setDisable(true);
  };

  return (
    <div id="contacts">
      <div className="container vh highlight-bg contact_root">
        <div className="center">
          <div className="desktop">
            <Title size="5rem">
              <span style={{ color: "white" }}>
                <b>Contact Me</b>
              </span>
            </Title>
          </div>
        </div>
        <div className="center">
          <div className="mobile">
            <Title size="3rem">
              <span style={{ color: "white" }}>
                <b>Contact Me</b>
              </span>
            </Title>
          </div>
        </div>
        <div className="center">
          <div className="inner_contact">
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input
                type="text"
                name="user_name"
                value={name}
                placeholder="Enter Your Name"
                onChange={handleNameChange}
              />
              <label>Message</label>
              <textarea
                placeholder="Enter your message"
                style={{ height: "15rem" }}
                name="message"
                value={message}
                onChange={handleMessageChange}
              />
              <span className="flex">
                <input
                  disabled={disable}
                  type="submit"
                  className="button"
                  value="Send"
                />
                <button
                  style={{ marginLeft: "1rem" }}
                  type="button"
                  className="button"
                  aria-label="Reset"
                  onClick={() => reset()}
                >
                  Reset
                </button>
              </span>
            </form>
          </div>
        </div>
      </div>
      <Snackbar isRender={isRender} message={errorMessage} />
    </div>
  );
};

export default Contact;
