import React from "react";
import { Heading, Loader } from "../../components";
import { TalkCard } from "../../components/Card/TalkCard";
import { client } from "../../config";
import { Layout } from "../../container";

const Talks = () => {
  const [talks, setTalks] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTalks =
    talks &&
    talks.filter((talk) =>
      talk.fields.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "talks",
      })
      .then((res) => {
        setTalks(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      <div className="container">
        <div style={{ width: "4rem" }}>
          <Heading text="Talks" />
        </div>
        <input
          className="searchBox"
          placeholder="Search Talks"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div style={{ padding: "3rem 0" }}>
          {loading ? (
            <div className="center">
              <Loader />
            </div>
          ) : (
            <div className="gridFlexStart">
              {filteredTalks &&
                filteredTalks.map((talk) => (
                  <TalkCard
                    key={talk.sys.id}
                    title={talk.fields.title}
                    description={talk.fields.description}
                    url={talk.fields.url}
                    talkLogo={talk.fields.talkLogo.fields.file.url}
                    speakers={talk.fields.speakers}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Talks;
