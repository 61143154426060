import React from "react";
import { Link } from "react-router-dom";
import { Heading, Loader } from "../../components";
import { TalkCard } from "../../components/Card/TalkCard";
import { client } from "../../config";
import Space from "./Space";

const Talks = () => {
  const [talks, setTalks] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "talks",
      })
      .then((res) => {
        setTalks(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container" id="talks">
      <Heading text="Talks" />
      <div style={{ padding: "3rem 0" }}>
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="center">
              <div className="grid">
                {talks &&
                  talks.map(
                    (talk, index) =>
                      index < 4 && (
                        <TalkCard
                          key={talk.sys.id}
                          title={talk.fields.title}
                          description={talk.fields.description}
                          url={talk.fields.url}
                          talkLogo={talk.fields.talkLogo.fields.file.url}
                          speakers={talk.fields.speakers}
                        />
                      )
                  )}
              </div>
            </div>
            <Space gap="2rem" />
            <div className="center">
              <Link to="/talks" className="highlight">
                Check out all talks
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Talks;
