import React from "react";
import { Heading, Loader, Paragraph } from "../../components";
import { client } from "../../config";
import Space from "./Space";

const Experience = () => {
  const [experience, setExperience] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "experience",
      })
      .then((res) => {
        setExperience(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container" id="experience">
      <Heading text="Experience" />
      <Space gap="3rem" />
      <div className="center">
        <Paragraph>
          I&apos;m proud to have collaborated with some awesome companies
        </Paragraph>
      </div>
      <div style={{ padding: "3rem 0" }}>
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          <div className="center">
            <div className="grid">
              {experience &&
                experience.map((exp) => (
                  <img
                    aria-hidden="true"
                    onClick={() => window.open(exp.fields.url, "_blank")}
                    className="company_logo"
                    src={exp.fields.orgLogo.fields.file.url}
                    alt={exp.fields.orgLogo.fields.title}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Experience;
