import React from "react";
import { Layout } from "../../container";
import AboutMe from "./AboutMe";
import Blogs from "./Blogs";
import Contact from "./Contact";
import Experience from "./Experience";
import Header from "./Header";
import Projects from "./Projects";
import Space from "./Space";
import "./styles.css";
import Talks from "./Talks";

const Home = () => {
  return (
    <Layout>
      <Space gap="3rem" />
      <Header />
      <Space gap="7rem" />
      <Projects />
      <Space gap="2rem" />
      <div style={{ background: "white" }}>
        <Space gap="2rem" />
        <Experience />
      </div>
      <Space gap="5rem" />
      <Blogs />
      <div style={{ background: "white" }}>
        <Space gap="2rem" />
        <Talks />
      </div>
      <Space gap="5rem" />
      <AboutMe />
      <Space gap="5rem" />
      <Contact />
      <Space gap="5rem" />
    </Layout>
  );
};

export default Home;
