import React from "react";
import {
  Highlight,
  Loader,
  Paragraph,
  SocialMedia,
  Title,
} from "../../components";
import { client } from "../../config";
import "./styles.css";

const Header = () => {
  const [bio, setBio] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "aboutMe",
      })
      .then((res) => {
        setBio(res.items[0].fields);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="desktop">
            <div className="container">
              <div className="row">
                <div className="column column-50">
                  <img
                    style={{ width: "65rem", height: "auto", float: "right" }}
                    src={bio && bio.heroAvatar.fields.file.url}
                    alt="Avatar"
                  />
                </div>
                <div className="column column-50 vh">
                  <div className="vh-center">
                    <div className="bold font">
                      <Highlight upperCase>Software Engineer</Highlight>
                    </div>
                    <Title font="Days One">
                      <div className="size1">Hello, I&apos;m Sayan</div>
                    </Title>
                    <div className="widthWrapper font">
                      <Paragraph>{bio && bio.briefBio}</Paragraph>
                    </div>
                    <div className="m2 flex">
                      <a className="button mr-2" href="#contacts">
                        Get in touch
                      </a>
                      <SocialMedia />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile">
            <div className="middle">
              <img
                style={{ width: "46rem", height: "auto", marginBottom: "2rem" }}
                src={bio && bio.heroAvatar.fields.file.url}
                alt="Avatar"
              />
              <div>
                <div className="bold font">
                  <Highlight upperCase>Software Engineer</Highlight>
                </div>
                <Title font="Days One">
                  <div className="size1">Hello, I&apos;m Sayan</div>
                </Title>
                <div className="widthWrapper font">
                  <Paragraph>{bio && bio.briefBio}</Paragraph>
                </div>
                <div className="m2">
                  <a className="button" href="#contacts">
                    Get in touch
                  </a>
                  <SocialMedia />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
