import React from "react";
import { client } from "../../config";
import "./styles.css";

const SocialMedia = () => {
  const [socialLinks, setSocialLinks] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "socialLinks",
      })
      .then((res) => {
        setSocialLinks(res.items[0].fields);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading && socialLinks && (
        <div className="flex">
          <a href={socialLinks.linkedIn} target="_blank" rel="noreferrer">
            <img className="icon" src="./icons/linkedin.svg" alt="LinkedIn" />
          </a>
          <a href={socialLinks.gitHub} target="_blank" rel="noreferrer">
            <img className="icon" src="./icons/github.svg" alt="GitHub" />
          </a>
          <a href={socialLinks.twitter} target="_blank" rel="noreferrer">
            <img className="icon" src="./icons/twitter.svg" alt="Twitter" />
          </a>
          <a href={socialLinks.youTube} target="_blank" rel="noreferrer">
            <img className="icon" src="./icons/youtube.svg" alt="YouTube" />
          </a>
        </div>
      )}
    </>
  );
};

export { SocialMedia };
