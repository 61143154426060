import PropTypes from "prop-types";
import React from "react";
import "./styles.css";

const Snackbar = ({ isRender, message }) => {
  const [show, setShowClass] = React.useState("");

  React.useEffect(() => {
    if (isRender) setShowClass("show");
    else setShowClass("");
  }, [isRender]);

  return (
    <div id="snackbar" className={`${show} highlight-bg`}>
      {message}
    </div>
  );
};

Snackbar.propTypes = {
  isRender: PropTypes.bool,
  message: PropTypes.string,
};

export { Snackbar };
