import React from "react";
import { Paragraph, SocialMedia } from "../../components";
import "./styles.css";

const Footer = () => {
  return (
    <div className="center">
      <div>
        <div className="center m1">
          <SocialMedia />
        </div>
        <Paragraph>Sayan Mondal @ 2022</Paragraph>
      </div>
    </div>
  );
};

export { Footer };
