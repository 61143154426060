import PropTypes from "prop-types";
import React from "react";

const Highlight = ({ font, upperCase, children }) => {
  return (
    <div className="highlight" style={{ fontFamily: font || "Nunito" }}>
      {upperCase ? children.toUpperCase() : children}
    </div>
  );
};

Highlight.propTypes = {
  children: PropTypes.element,
  font: PropTypes.string,
  upperCase: PropTypes.bool,
};

export { Highlight };
