import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blogs from "./pages/Blogs";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Talks from "./pages/Talks";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/talks" element={<Talks />} />
        <Route exact path="/blogs" element={<Blogs />} />
      </Routes>
    </Router>
  );
};

export default App;
