import React from "react";
import { Link } from "react-router-dom";
import { BlogCard, Heading, Loader } from "../../components";
import { client } from "../../config";
import Space from "./Space";

const Blogs = () => {
  const [blogs, setBlogs] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "blogs",
      })
      .then((res) => {
        setBlogs(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container" id="blogs">
      <Heading text="Blogs" />
      <div style={{ padding: "3rem 0" }}>
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="center">
              <div className="grid">
                {blogs &&
                  blogs.map(
                    (blog, index) =>
                      index < 3 && (
                        <BlogCard
                          title={blog.fields.title}
                          description={blog.fields.description}
                          url={blog.fields.url}
                        />
                      )
                  )}
              </div>
            </div>
            <Space gap="2rem" />
            <div className="center">
              <Link to="/blogs" className="highlight">
                Check out all blogs
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
