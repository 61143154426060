import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { init } from "@emailjs/browser";
import App from "./App";
import "./index.css";

ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

init(process.env.REACT_APP_EMAILJS_USER_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
