import React from "react";
import { Link } from "react-router-dom";
import { Heading, Loader, ProjectCard } from "../../components";
import { client } from "../../config";
import Space from "./Space";

const Projects = () => {
  const [projects, setProjects] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "projects",
      })
      .then((res) => {
        setProjects(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container" id="projects">
      <Heading text="Projects" />
      <Space gap="3rem" />
      {loading ? (
        <div className="center">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="grid">
            {projects &&
              projects.map(
                (project, index) =>
                  index < 5 && (
                    <ProjectCard
                      title={project.fields.title}
                      description={project.fields.description}
                      url={project.fields.url}
                      image={project.fields.image.fields.file.url}
                      alt={project.fields.image.fields.title}
                    />
                  )
              )}
          </div>
          <Space gap="2rem" />
          <div className="center">
            <Link to="/projects" className="highlight">
              Check out all Projects
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
