import React from "react";
import { Loader, Paragraph, Title } from "../../components";
import { client } from "../../config";

const AboutMe = () => {
  const [data, setData] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "aboutMe",
      })
      .then((res) => {
        setData(res.items[0].fields);
        setLoading(false);
      });
  }, []);

  return (
    <div id="aboutme">
      {loading ? (
        <div className="center">
          <Loader />
        </div>
      ) : (
        data && (
          <div>
            <div className="container desktop">
              <div style={{ padding: "3rem 0" }}>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ width: "50%" }}>
                    <Title size="4rem">
                      <b>About Me</b>
                    </Title>
                    <Paragraph>{data.bio}</Paragraph>
                    <a
                      className="button m1"
                      href={data.resume.fields.file.url}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download my Resume
                    </a>
                  </div>
                  <img
                    style={{
                      height: "30rem",
                      width: "30rem",
                      borderRadius: "50%",
                    }}
                    src={data.avatar.fields.file.url}
                    alt="Avatar"
                  />
                </div>
              </div>
            </div>
            <div className="container mobile">
              <div className="center">
                <div style={{ padding: "3rem 3rem", textAlign: "center" }}>
                  <img
                    style={{
                      height: "auto",
                      width: "25rem",
                      borderRadius: "50%",
                    }}
                    src={data.avatar.fields.file.url}
                    alt="Avatar"
                  />
                  <div style={{ padding: "3rem 0" }}>
                    <Title size="3rem">
                      <b>About Me</b>
                    </Title>
                    <Paragraph style={{ width: "10%" }}>{data.bio}</Paragraph>
                    <a
                      className="button m1"
                      href={data.resume.fields.file.url}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download my Resume
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AboutMe;
