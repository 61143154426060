import PropTypes from "prop-types";
import React from "react";
import "./styles.css";

const Heading = ({ text }) => {
  return (
    <div>
      <div className="heading">{text}</div>
      <hr className="dash highlight-bg" />
    </div>
  );
};

Heading.propTypes = {
  text: PropTypes.string,
};

export { Heading };
