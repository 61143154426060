import PropTypes from "prop-types";
import React from "react";

const Space = ({ gap }) => <div style={{ height: gap }} />;

Space.propTypes = {
  gap: PropTypes.string,
};

export default Space;
