import PropTypes from "prop-types";
import React from "react";
import { Paragraph, Title } from "../..";
import { Speaker } from "../../Speaker";
import "./styles.css";

const TalkCard = ({ title, description, url, speakers, talkLogo }) => {
  const length = 150;
  const trimmedString =
    description.length > length
      ? `${description.substring(0, length - 3)}...`
      : description;

  return (
    <div
      className="talkcard_root"
      onClick={() => window.open(url, "_blank")}
      aria-hidden="true"
    >
      <img src="./icons/banner.png" alt="banner" className="purpleTop" />
      <div className="flex">
        <img className="talkLogo" src={talkLogo} alt={talkLogo} />
        <div className="talkContent">
          <Title>
            <b>{title}</b>
          </Title>
          <Paragraph>{trimmedString}</Paragraph>
          <div className="flex">
            {speakers.map((speaker) => (
              <Speaker
                name={speaker.name}
                avatar={speaker.avatar}
                designation={speaker.designation}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TalkCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  talkLogo: PropTypes.string,
  speakers: PropTypes.arrayOf(PropTypes.object),
};

export { TalkCard };
