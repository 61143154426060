import PropTypes from "prop-types";
import React from "react";
import { Paragraph, Title } from "../..";
import Space from "../../../pages/Home/Space";
import "./styles.css";

const BlogCard = ({ title, description, url }) => {
  return (
    <div
      className="blogcard_root"
      onClick={() => window.open(url, "_blank")}
      aria-hidden="true"
    >
      <div className="purpleBox" />
      <div className="deepPurpleBox" />
      <div className="blog_content">
        <Title>
          <b>{title}</b>
        </Title>
        <Space gap="2rem" />
        <Paragraph>{description}</Paragraph>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};

export { BlogCard };
