import React from "react";
import { Heading, Loader, ProjectCard } from "../../components";
import { client } from "../../config";
import { Layout } from "../../container";
import Space from "../Home/Space";

const Projects = () => {
  const [projects, setProjects] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProjects =
    projects &&
    projects.filter((project) =>
      project.fields.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "projects",
      })
      .then((res) => {
        setProjects(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      <div className="container">
        <div style={{ width: "4rem" }}>
          <Heading text="Projects" />
        </div>
        <input
          className="searchBox"
          placeholder="Search Projects"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Space gap="2rem" />
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          <div className="gridFlexStart">
            {filteredProjects &&
              filteredProjects.map((project) => (
                <ProjectCard
                  title={project.fields.title}
                  description={project.fields.description}
                  url={project.fields.url}
                  image={project.fields.image.fields.file.url}
                  alt={project.fields.image.fields.title}
                />
              ))}
          </div>
        )}
      </div>
      <Space gap="5rem" />
    </Layout>
  );
};

export default Projects;
