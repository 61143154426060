import PropTypes from "prop-types";
import React from "react";
import "./styles.css";

const Title = ({ size, font, children }) => {
  return (
    <div
      className="title"
      style={{ fontSize: size, fontFamily: font || "Nunito" }}
    >
      {children}
    </div>
  );
};

Title.propTypes = {
  children: PropTypes.element,
  size: PropTypes.string,
  font: PropTypes.string,
};

export { Title };
