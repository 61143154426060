import React from "react";
import { BlogCard, Heading, Loader } from "../../components";
import { client } from "../../config";
import { Layout } from "../../container";
import "./styles.css";

const Blogs = () => {
  const [blogs, setBlogs] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredBlogs =
    blogs &&
    blogs.filter((blog) =>
      blog.fields.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

  React.useEffect(() => {
    setLoading(true);
    client
      .getEntries({
        content_type: "blogs",
      })
      .then((res) => {
        setBlogs(res.items);
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      <div className="container">
        <div style={{ width: "4rem" }}>
          <Heading text="Blogs" />
        </div>
        <input
          className="searchBox"
          placeholder="Search Blogs"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div style={{ padding: "3rem 0" }}>
          {loading ? (
            <div className="center">
              <Loader />
            </div>
          ) : (
            <div className="gridFlexStart">
              {filteredBlogs &&
                filteredBlogs.map((blog) => (
                  <BlogCard
                    title={blog.fields.title}
                    description={blog.fields.description}
                    url={blog.fields.url}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Blogs;
