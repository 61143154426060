import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Speaker = ({ name, designation, avatar }) => {
  return (
    <div className="flex speaker">
      <img className="avatar" src={avatar} alt="Avatar" />
      <div className="speaker_content">
        <div className="speaker_header">{name}</div>
        <div className="speaker_designation paragraph">{designation}</div>
      </div>
    </div>
  );
};

Speaker.propTypes = {
  name: PropTypes.string,
  designation: PropTypes.string,
  avatar: PropTypes.string,
};

export { Speaker };
