/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Link } from "react-router-dom";
import { Paragraph, Title } from "../../components";
import "./styles.css";

const Header = () => {
  const [showNav, setShowNav] = React.useState(true);

  const pathname = window.location.pathname.split("/");

  React.useEffect(() => {
    if (pathname[1] !== "" && pathname[1][0] !== "#") setShowNav(false);
    else setShowNav(true);
  }, [pathname]);

  return (
    <>
      <div className="container desktop nav">
        <div className="row">
          <div className="column column-30">
            <Title>
              <div className="header">
                <img className="logo" src="./icons/logo.png" alt="Logo" />
              </div>
            </Title>
          </div>
          <div className="column column-70">
            <Paragraph>
              {showNav ? (
                <ul className="menuItems header">
                  <li>
                    <a className="navLinks" href="#aboutme">
                      About
                    </a>
                  </li>
                  <li>
                    <a className="navLinks" href="#experience">
                      Experience
                    </a>
                  </li>
                  <li>
                    <a className="navLinks" href="#projects">
                      Projects
                    </a>
                  </li>
                  <li>
                    <a className="navLinks" href="#blogs">
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a className="navLinks" href="#talks">
                      Talks
                    </a>
                  </li>
                  <li>
                    <a className="navLinks" href="#contacts">
                      Contacts
                    </a>
                  </li>
                </ul>
              ) : (
                <div className="singleMenuItems header">
                  <Link to="/" className="navLinks">
                    Back to Home
                  </Link>
                </div>
              )}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="center">
          <Title>
            <div className="header">
              <img className="logo" src="./icons/logo.png" alt="Logo" />
            </div>
          </Title>
        </div>
      </div>
    </>
  );
};

export { Header };
