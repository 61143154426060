import PropTypes from "prop-types";
import React from "react";
import { Footer, Header } from "..";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

export { Layout };
