import PropTypes from "prop-types";
import React from "react";
import { Paragraph, Title } from "../..";
import "./styles.css";

const ProjectCard = ({ title, description, image, url, alt }) => {
  return (
    <>
      <div className="desktop">
        <div className="project">
          <img className="project_image" src={image} alt={alt} />
          <div className="content">
            <Title size="4rem">{title}</Title>
            <Paragraph>{description}</Paragraph>
            <div className="card_bottom">
              <a className="button" href={url} target="_blank" rel="noreferrer">
                See Project
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <a className="navLinks" href={url} target="_blank" rel="noreferrer">
          <div className="project_m">
            <img className="project_image_m" src={image} alt={alt} />
            <div className="content_m">
              <Title size="3rem">{title}</Title>
              <Paragraph>{description}</Paragraph>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string,
};

export { ProjectCard };
